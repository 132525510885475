import {Client, createClient} from '@liveblocks/client';
import {LiveblocksProvider} from '@liveblocks/react';
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';
import {QueryCache, QueryClient} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import {init as commandBarInit} from 'commandbar';
import LogRocket from 'logrocket';
import {Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import MainContainer from './MainContainer';
import PageLoader from './components/PageLoader';
import config from './config';
import Analytics from './services/analytics/Analytics';
import {StoreProvider} from './store/StoreProvider';

import {
  BrowserTracing,
  Replay,
  captureException as SentryCaptureException,
  init as SentryInit,
} from '@sentry/browser';
import {ErrorBoundary as SentryErrorBoundary} from '@sentry/react';
import {Result} from 'antd';
import ContainerDimensions from 'react-container-dimensions';
import {TourProvider} from './components/ProductTour/TourContext';
import Error500 from './components/common/Error/Error500';
import loadIntercomScripts from './services/interconScripts';
import {useEffect} from 'react';

// Initialise react-query with a local-storage caching mechanism
// Refer -> https://react-query.tanstack.com/plugins/persistQueryClient
// Test this thoroughly if react-query version is being updated.
// localStorage key `REACT_QUERY_OFFLINE_CACHE_V1` is cleared on logout to invalidate the cache

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 1 day
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.meta?.sentryMessage) {
        SentryCaptureException(query?.meta?.sentryMessage);
      }

      if (query?.meta?.silentOnError) return;
    },
  }),
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  key: config.reactQueryOfflineCacheKey,
});

if (config.logRocketKey) {
  LogRocket.init(config.logRocketKey);
}

// Initialising commandbar

if (config.commandBarApiKey) {
  try {
    commandBarInit(config.commandBarApiKey);
  } catch {}
}

// Initialising user tracking

Analytics.identify();

let client: Client;

// Initialise liveblocks
if (config.liveBlocksId) {
  client = createClient({
    publicApiKey: config.liveBlocksId,
  });
}

// init sentry

if (config.sentryDsnKey) {
  SentryInit({
    dsn: config.sentryDsnKey,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    integrations: [
      new BrowserTracing(),
      new Replay({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],

    // To set a uniform sample rate
    tracesSampleRate: 0.2,
    environment: config.environment,
  });
}

function handleAppError(e: Error) {
  // if its a chunkLoad error it means the browser has an older file reference,
  // reload the page to get rid of it.
  if (e.name === 'ChunkLoadError') {
    window.location.reload();
  }
}

function App() {
  useEffect(() => {
    loadIntercomScripts();
  }, []);

  return (
    <ContainerDimensions>
      {({width}) => {
        const isMobile = width < 700;

        if (isMobile) {
          return (
            <SentryErrorBoundary
              fallback={
                <Error500
                  errorText={
                    "Sorry, something went wrong! Please reload the page if it doesn't reload automatically."
                  }
                  errorTitle={undefined}
                  retryText={undefined}
                />
              }
              onError={handleAppError}
            >
              <Result
                style={{height: '100vh', paddingTop: '10%'}}
                status="warning"
                title={'Relax'}
                icon={
                  <img
                    style={{width: '90vw', margin: '48px 0px'}}
                    src="/assets/under_construction.png"
                    alt="Under construction"
                  />
                }
                subTitle={
                  'We are working on a mobile version. For now, Houseware works only on larger screens. Preferably Desktop Chrome.'
                }
              />
            </SentryErrorBoundary>
          );
        }

        return (
          <SentryErrorBoundary
            fallback={
              <Error500
                errorText={
                  "Sorry, something went wrong! Please reload the page if it doesn't reload automatically."
                }
                errorTitle={undefined}
                retryText={undefined}
              />
            }
            onError={handleAppError}
          >
            <Router>
              <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{persister}}
              >
                <StoreProvider>
                  <LiveblocksProvider client={client}>
                    <Suspense
                      fallback={<PageLoader style={{width: '100vw', height: '100vh'}} />}
                    >
                      <TourProvider>
                        <MainContainer />
                      </TourProvider>
                    </Suspense>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </LiveblocksProvider>
                </StoreProvider>
              </PersistQueryClientProvider>
            </Router>
          </SentryErrorBoundary>
        );
      }}
    </ContainerDimensions>
  );
}

export default App;
